import React, { useState } from "react";

function ConsoleFrame() {
  return (
    <div className="h-full flex flex-col gap-8 md:gap-16 items-center justify-center">
      <div className="flex flex-col gap-2 md:gap-4 items-center text-white z-50">
        <h1 className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%]">
          Node Operators Simplified
        </h1>
        <p className="text-white/60 text-center text-sm md:text-base">
          One Click Deployment with Ethereum Economic Security Powered by
          EigenLayer
        </p>
      </div>
      <div className=" w-[96%] md:w-3/4 flex justify-center relative videoThumbnail">
        <div className="border border-white/20 -z-[1]">
          <img src="console.webp" className=" grayscale relative " alt="logo" />
        </div>
        <a
          href="https://console.nodeops.xyz/"
          target="_blank"
          rel="noreferrer"
          className="text-white absolute z-[9999] place-self-center pixelButton"
        >
          Deploy Node
        </a>
      </div>
    </div>
  );
}

export default ConsoleFrame;
