import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { FaDiscord, FaGithub, FaXTwitter, FaYoutube } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { Link, animateScroll as scroll } from "react-scroll";
import { TbFileFilled } from "react-icons/tb";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const minWindowSize = 768;

  const toggleNav = () => {
    const nav = document.querySelector(
      windowWidth <= minWindowSize ? ".mobileNav" : ".nav"
    );
    const navTop = document.querySelector(".nav-top");
    const navItems = document.querySelector(".nav-items");
    const navHome = document.querySelector(".nav-home");
    // const icon = document.querySelector(".hamburger");

    if (!isOpen) {
      gsap.to(nav, {
        // height: "370px",
        height: "130px",
        duration: 0.75,
        ease: "power4.inOut",
        immediateRender: false,
      });

      gsap.to(navTop, {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        onStart: () => {
          gsap.set(navTop, { display: "block" });
        },
        delay: 0.5,
        immediateRender: false,
      });

      gsap.to(navItems, {
        opacity: 0,
        duration: 0.1,
        onComplete: () => {
          gsap.set(navItems, { display: "none" });
        },
        immediateRender: false,
      });

      gsap.to(navHome, {
        flexGrow: 1,
        duration: 0.2,
        ease: "power4.inOut",
        delay: 0,
        immediateRender: false,
        // onComplete: () => {
        //   icon.textContent = "X";
        // },
      });
    } else {
      gsap.to(nav, {
        height: "60px",
        duration: 0.75,
        ease: "power4.inOut",
        delay: 0.2,
        immediateRender: false,
      });

      gsap.to(navTop, {
        opacity: 0,
        scale: 0.9,
        duration: 0.2,
        onComplete: () => {
          gsap.set(navTop, { display: "none" });
        },
        immediateRender: false,
      });

      gsap.to(navHome, {
        flexGrow: 0,
        duration: 0.2,
        ease: "power4.inOut",
        // onComplete: () => {
        //   icon.textContent = "+";
        // },
        immediateRender: false,
      });

      gsap.to(navItems, {
        opacity: 1,
        delay: 0.5,
        duration: 0.2,
        onStart: () => {
          gsap.set(navItems, { display: "flex" });
        },
        immediateRender: false,
      });
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth <= minWindowSize ? (
        <div className="mobileNav">
          <div className="nav-top">
            <div className="social-links">
              <div className="col">
                <a
                  href="https://twitter.com/NodeOps_App"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaXTwitter />
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="https://discord.gg/HftrtmSsyW"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaDiscord />
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="https://github.com/NodeOps-app"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaGithub />
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="mailto:hello@nodeops.xyz"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FiMail />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="nav-bottom">
            <div className="nav-home" onClick={toggleNav}>
              <div className="nav-item" id="toggleBtn">
                {/* <div className="hamburger">+</div> */}
                <p>Follow Us</p>
              </div>
            </div>
            <div className="nav-items">
              <Link
                to="hero"
                smooth={true}
                duration={1000}
                className="nav-item nav-logo"
              >
                <img src="Logo5.svg" alt="logo" className="h-[20px]" />
              </Link>
              {/*   <Link
                to="about"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                About
              </Link>
              <Link
                to="features"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Features
              </Link>
              <Link
                to="process"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Process
              </Link>
              <Link
                to="protocol"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Protocol
      </Link>*/}
              <a
                className="nav-item"
                href="https://nodeopsxyz.notion.site/Careers-page-NodeOps-9644e68d693c4aafb5868ded71fbc483?pvs=4"
                target="_blank"
              >
                Careers
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="nav">
          {/* <div className="nav-top">
        <div className="more-links">
          <div className="col">
            <div className="col-title">
              <p>Awards</p>
            </div>
            <div className="link">
              <a href="#">Home</a>
            </div>
            <div className="link">
              <a href="#">About</a>
            </div>
            <div className="link">
              <a href="#">Contact</a>
            </div>
            <div className="link">
              <a href="#">Help</a>
            </div>
          </div>

          <div className="col">
            <div className="col-title">
              <p>Menu 2</p>
            </div>
            <div className="link">
              <a href="#">Home</a>
            </div>
            <div className="link">
              <a href="#">About</a>
            </div>
            <div className="link">
              <a href="#">Contact</a>
            </div>
            <div className="link">
              <a href="#">Help</a>
            </div>
          </div>

          <div className="col">
            <div className="col-title">
              <p>Menu 3</p>
            </div>
            <div className="link">
              <a href="#">Home</a>
            </div>
            <div className="link">
              <a href="#">About</a>
            </div>
            <div className="link">
              <a href="#">Contact</a>
            </div>
            <div className="link">
              <a href="#">Help</a>
            </div>
          </div>

          <div className="col">
            <div className="col-title">
              <p>Menu 4</p>
            </div>
            <div className="link">
              <a href="#">Home</a>
            </div>
            <div className="link">
              <a href="#">About</a>
            </div>
            <div className="link">
              <a href="#">Contact</a>
            </div>
            <div className="link">
              <a href="#">Help</a>
            </div>
          </div>
        </div>
      </div> */}
          <div className="nav-top">
            <div className="social-links">
              <div className="col">
                <a
                  href="https://twitter.com/NodeOps_App"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaXTwitter />
                    {" / Twitter"}
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="https://discord.gg/HftrtmSsyW"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaDiscord />
                    {" / Discord"}
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="https://github.com/NodeOps-app"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaGithub />
                    {" / Github"}
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="https://www.youtube.com/channel/UCyn3oQylmGbxgFksUv_pyEw"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FaYoutube />
                    {" / YouTube"}
                  </div>
                </a>
              </div>

              <div className="col">
                <a
                  href="mailto:hello@nodeops.xyz"
                  target="_blank"
                  className="relative z-[9999]"
                >
                  <div className="flex justify-center items-center text-white btn">
                    <FiMail />
                    {" / Mail"}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="nav-bottom">
            <div className="nav-home" onClick={toggleNav}>
              <div className="nav-item" id="toggleBtn">
                {/* <div className="hamburger">+</div> */}
                <p>Follow Us</p>
              </div>
            </div>
            <div className="nav-items">
              <Link
                to="hero"
                smooth={true}
                duration={1000}
                className="nav-item nav-logo"
              >
                <img src="Logo5.svg" alt="logo" className="h-[20px]" />
              </Link>
              {/*} <Link
                to="about"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                About
              </Link>
              <Link
                to="features"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Features
              </Link>
              <Link
                to="process"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Process
              </Link>
              <Link
                to="protocol"
                smooth={true}
                duration={1000}
                className="nav-item"
              >
                Protocol
    </Link> */}
              <a
                className="nav-item"
                href="https://nodeopsxyz.notion.site/Careers-page-NodeOps-9644e68d693c4aafb5868ded71fbc483?pvs=4"
                target="_blank"
              >
                Careers
              </a>
              <a
                className="nav-item"
                href="https://nodeops.gitbook.io/nodes-info"
                target="_blank"
              >
                Docs
              </a>
              <a
                className="nav-item"
                href="https://nodeops.substack.com"
                target="_blank"
              >
                Blog
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
