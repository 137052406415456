import React, { useRef, useEffect } from "react";

function Header() {
  const vidRef = useRef();
  useEffect(() => {
    // vidRef.current.play();
  }, []);
  return (
    <div className="h-full flex flex-col items-center justify-center header">
      <div className="flex flex-col gap-2 md:gap-6 text-center md:text-left z-50">
        <h2 className="font-[Panchang] text-white font-bold text-4xl md:text-[4rem] mt-[100px]">
          NodeOps
        </h2>
        <p className="text-white/60 text-sm md:text-base text-center">
          Simplifying the Life for Node Operators and Developers
          <br />
          Value Capture Engine for Node Operators
        </p>
      </div>
      <div>
        <video src="Hero3.webm" ref={vidRef} muted autoPlay loop />
      </div>
      {/* <div style={style}>
        <Spline scene="https://prod.spline.design/Ds5c1l1rr7T-BHnP/scene.splinecode" />
      </div> */}
      {/* <div className="w-[1400px] h-[500px]">
        <iframe
          src="https://my.spline.design/untitled1-bc42ada817a23ef3fb2a0ee15fd5fb33/"
          frameborder="0"
          width="100%"
          height="100%"
          className="z-[100000] absolute"
        ></iframe>
      </div> */}
    </div>
  );
}

export default Header;

// Allow user to rotate menu horizontally/vertically
// User can change logo color by clicking on it
// User can drag & drop menu/logo
// Pixel transition on chain logo
// Pixel animation on button hover
// Chat bubble on contact us
