import "./App.css";
import Header from "./component/Header";
import InfoCard from "./component/InfoCard/InfoCard";
import VideoFrame from "./component/VideoDemo/VideoFrame";
import Carousel from "./component/ChainLogos/Carousel";
import Footer from "./component/Footer/Footer";
import Features from "./component/Features/Features";
import React, { useState, useEffect } from "react";
import Testimonials from "./component/Testimonials/Testimonials";
import Navbar from "./component/Menu/Navbar";
import Team from "./component/Team/Team";
import ConsoleFrame from "./component/Console/ConsoleFrame";
import ChainTable from "./component/ChainLogos/ChainTable";
import TrendingNow from "./component/TrendingNow/TrendingNow";

function App() {
  return (
    <div className="relative">
      <div className="content">
        {/* <Logo /> */}
        {/* <Menu /> */}
        <Navbar />
        <div className="flex flex-col mainContainer">
          <div className="fixed top-6 z-[1000000] self-center">
            {/* <img src="Logo.svg" alt="logo" className="h-[50px]" /> */}
            {/* <img src="Logo5.svg" alt="logo" className="h-[70px]" /> */}
          </div>
          <div className="px-6">
            <section id="hero">
              <Header />
            </section>
            {/* <section id="about">
                <VideoFrame />
              </section> */}

            <section id="about">
              <ConsoleFrame />
            </section>

            <section className="h-auto" id="features">
              <Features />
            </section>
            {/* <section id="process">
              <InfoCard />
            </section> */}
            <section className="h-auto py-12" id="protocol">
              {/* <Carousel /> */}
              <ChainTable />
            </section>

            <section className="h-auto py-12">
              <Testimonials />
            </section>
            <section className="h-auto py-12" id="trendingNow">
              <TrendingNow />
            </section>
            <section id="team" className="h-auto">
              <Team />
            </section>
            <section className="h-auto">
              <Footer />
            </section>
          </div>
          {/*<EarlyAccessCard /> */}
          {/* <div style={style}>
        <Spline scene="https://prod.spline.design/Ds5c1l1rr7T-BHnP/scene.splinecode" />
      </div> */}
          {/* <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: "-10",
          height: "160vh",
          width: "100%",
          maxWidth: "100%",
          opacity: 0.4,
        }}
      >
        <g clip-path="url(#clip0_402_544)">
          <rect
            width="100%"
            height="100%"
            fill="url(#paint0_linear_402_544)"
          ></rect>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_402_544"
            x1="1304"
            y1="1.27213e-05"
            x2="741.984"
            y2="765.466"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.00101833" stop-color="#0F524D"></stop>
            <stop offset="1" stop-opacity="0"></stop>
          </linearGradient>
          <clipPath id="clip0_402_544">
            <rect width="100%" height="100%" fill="white"></rect>
          </clipPath>
        </defs>
      </svg> */}
        </div>
      </div>
    </div>
  );
}

export default App;
