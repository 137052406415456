import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Navigation } from "swiper/modules";
import {
  PiCaretLeftBold,
  PiCaretRightBold,
  PiArrowUpRightBold,
} from "react-icons/pi";

export default function TrendingNow() {
  const newsData = [
    {
      title: "Aethir",
      image: "News1.webp",
      description: "Aethir Checker Nodes Live Now On NodeOps Console",
      link: "https://console.nodeops.xyz/",
      linkTitle: "Deploy Now",
    },
    {
      title: "Restake & Earn - EigenLayer",
      image: "News2.webp",
      description: "Restake & Earn NODE Points on EigenLayer",
      link: "https://twitter.com/NodeOps_App/status/1782788870792208826",
      linkTitle: "Participate Now",
    },
    {
      title: "Hychain",
      image: "News3.webp",
      description: "11k+ Hychain Guardian Node Keys Powered by NodeOps",
      link: "https://x.com/NodeOps_App/status/1780950116284690529",
      linkTitle: "View",
    },
    {
      title: "OraiChain",
      image: "News4.webp",
      description:
        "NodeOps Partners with OraiChain for One-Click Deployment for Cosmos Ecosystem",
      link: "https://nodeops.substack.com/p/nodeops-partners-with-oraichain-to",
      linkTitle: "View",
    },
    {
      title: "XAI Premium",
      image: "News5.webp",
      description: "Introducing XAI Premium for Sentry Node Operators",
      link: "https://twitter.com/NodeOps_App/status/1770384084440670395",
      linkTitle: "View",
    },
  ];
  return (
    <div className="h-[500px] flex flex-col justify-center">
      <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
        <div className="flex gap-4 flex-col md:self-end w-full md:w-auto">
          <div className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%] md:text-left z-50">
            Trending
            <br />
            Now
          </div>
          <div className="flex place-self-end text-2xl">
            <div
              id="previousBtn"
              className="text-white/20 hover:text-white z-[9999] border border-white/20 p-4 cursor-pointer"
            >
              <PiCaretLeftBold />
            </div>
            <div
              id="nextBtn"
              className="text-white/20 hover:text-white z-[9999] border border-white/20 p-4 cursor-pointer"
            >
              <PiCaretRightBold />
            </div>
          </div>
        </div>
        <Swiper
          // slidesPerView={2}
          // spaceBetween={30}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          navigation={{
            nextEl: "#nextBtn",
            prevEl: "#previousBtn",
          }}
          //   loop={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper text-white z-[9999]"
        >
          {newsData.map((news, index) => (
            <SwiperSlide className="flex flex-col" key={index}>
              <div className="">
                <img src={news.image} alt="News logo" className="newsCardImg" />
              </div>
              <div className="w-full border-t border-white/20 px-4 py-4 flex flex-col text-sm text-white/60">
                <div className="place-self-start text-white">{news.title}</div>
              </div>
              <div className="w-full border-t border-white/20 flex flex-wrap self-start text-[0.8rem] text-white/60 h-[80px]">
                <span
                  className="px-4 py-2 text-left"
                  style={{ wordBreak: "break-word" }}
                >
                  {news.description}
                </span>
              </div>
              <div className="text-xs text-white/60 place-self-end p-4">
                <a
                  href={news.link}
                  target="_blank"
                  className="flex gap-1 justify-center items-center"
                >
                  {news.linkTitle}
                  <PiArrowUpRightBold />
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
