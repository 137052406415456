import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import {
  PiCaretLeftBold,
  PiCaretRightBold,
  PiArrowUpRightBold,
} from "react-icons/pi";

export default function ChainTable() {
  const images = [
    "eigenlayer.svg",
    "aethir.svg",
    "xai.svg",
    "Celestia.svg",
    "Shardeum.svg",
    "zora.svg",
    "avail.svg",
    "Fuel.svg",
    "hychain.svg",
    "NeuralInternet.svg",
    "oraichain.svg",
    "akash.svg",
    "archway.svg",
    "atom.svg",
    "AssetMantle.svg",
    "TeraClassic.svg",
    "omniflix.svg",
    "coreum.svg",
    "osmo.svg",
    "selfChain.svg",
    "aptos.svg",
    "Babylon.png",
    "Taiko.svg",
    "Carv.svg",
    "ParticleNetwork.svg",
    "Rivalz.svg",
    // "powerloom.svg",
  ];
  const chain = [
    {
      name: "EigenLayer",
      action: ["Delegate"],
      url: [
        "https://app.eigenlayer.xyz/operator/0xb5Ead7A953052dA8212DA7e9462D65F91205d06D",
      ],
      keywords: ["Operator"],
    },
    {
      name: "Aethir",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "XAI Sentry Node Setup",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Celestia",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Shardeum",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Zora",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Avail",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Fuel",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "HyChain",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Neural Internet",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Oraichain",
      action: ["Access Now"],
      url: ["https://console.nodeops.xyz/"],
      keywords: ["Infrastructure Support"],
    },
    {
      name: "Akash",
      action: ["Access Now"],
      url: ["https://akash.nodeops.xyz/"],
      keywords: ["Infrastructure"],
    },
    {
      name: "Archway",
      action: ["Access Now"],
      url: ["https://archway.nodeops.xyz/"],
      keywords: ["Infrastructure"],
    },
    {
      name: "Cosmos Hub",
      action: ["Access Now"],
      url: ["https://cosmos.nodeops.xyz/"],
      keywords: ["Infrastructure"],
    },
    {
      name: "AssetMantle",
      action: ["Delegate"],
      url: ["https://wallet.assetmantle.one/stake"],
      keywords: ["Validator", "Delegate"],
    },
    {
      name: "Terra Classic",
      action: ["Delegate"],
      url: ["https://station.terraclassic.community/stake"],
      keywords: ["Validator", "Delegate"],
    },
    {
      name: "OmniFlix",
      action: ["Delegate", "Playground"],
      url: [
        "https://www.mintscan.io/omniflix/validators/omniflixvaloper1l00ax4eaxfd7q0h37cy8jesggpqz77tmdh4tmu",
        "https://playgroundv2.nodeops.xyz",
      ],
      keywords: ["Validator", "Delegate", "Playground"],
    },

    {
      name: "Coreum",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Osmosis",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Self Chain",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Validator", "Infrastructure"],
    },
    {
      name: "Aptos",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Babylon",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Taiko",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Carv",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Particle Network",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    {
      name: "Rivalz",
      action: ["Coming Soon"],
      url: [],
      keywords: ["Infrastructure"],
    },
    // {
    //   name: "Powerloom",
    //   action: "Coming Soon",
    //   url: "",
    //   keywords: ["Infrastructure Support"],
    // },
  ];

  return (
    <div className=" flex flex-col justify-center">
      <div className="flex flex-col gap-8 justify-center items-center">
        <div className="flex gap-4 flex-col w-full md:w-auto">
          <div className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%] z-50">
            Protocol
            <br />
            Supported
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 text-white z-[9999] gap-6">
          {images.map((image, index) => (
            <div className="flex flex-col border border-white/20" key={index}>
              <div className="flex justify-center items-center py-8">
                {/* <div className={`tiles tiles-${index}`} key={index}></div> */}
                <img
                  src={image}
                  alt="chain logo"
                  className="w-[100px] h-[100px]"
                />
              </div>
              <div className="w-full border-t border-white/20 px-4 py-4 flex flex-col text-sm text-white/60">
                <div className="place-self-start text-white">
                  {chain[index]?.name}
                </div>
                <div className="place-self-start text-xs flex justify-between gap-4 flex-wrap">
                  {chain[index]?.url.length > 0
                    ? chain[index]?.url.map((item, i) => (
                        <a
                          href={item}
                          target="_blank"
                          className="flex gap-1 justify-center items-center"
                          key={i}
                        >
                          {chain[index]?.action[i]}
                          <PiArrowUpRightBold />
                        </a>
                      ))
                    : chain[index]?.action[0]}
                </div>
              </div>
              <div className="w-full border-t border-white/20 flex flex-wrap self-start text-[0.6rem] text-white/60">
                <span className="px-4 py-2">
                  {chain[index]?.keywords.join(" / ")}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
