import React from "react";
import { Tweet } from "react-tweet";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function Testimonials() {
  return (
    <div className="w-full flex gap-8 flex-col justify-center overflow-hidden">
      <div className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center mt-2 z-50">
        Wall of Love
      </div>

      <article class="wrapper w-full">
        <div class="marquee">
          <div class="marquee__group">
            <Tweet id="1775209201352282462" />
            <Tweet id="1777493597916746205" />
            <Tweet id="1775431120957374694" />
            <Tweet id="1767381458170077601" />
            <Tweet id="1778704254372553068" />
            <Tweet id="1755980623448047803" />
            <Tweet id="1767768487760474357" />
            <Tweet id="1757760892694073478" />
            <Tweet id="1757089440890065342" />
            <Tweet id="1751482415371923679" />
            <Tweet id="1743661234359357761" />
            <Tweet id="1775217542606262414" />
            <Tweet id="1747971713311359371" />
            <Tweet id="1775021225267618233" />
            <Tweet id="1775035693921706440" />
            <Tweet id="1784185591166288135" />
          </div>

          <div aria-hidden="true" class="marquee__group">
            <Tweet id="1775209201352282462" />
            <Tweet id="1777493597916746205" />
            <Tweet id="1775431120957374694" />
            <Tweet id="1767381458170077601" />
            <Tweet id="1778704254372553068" />
            <Tweet id="1755980623448047803" />
            <Tweet id="1767768487760474357" />
            <Tweet id="1757760892694073478" />
            <Tweet id="1757089440890065342" />
            <Tweet id="1751482415371923679" />
            <Tweet id="1743661234359357761" />
            <Tweet id="1775217542606262414" />
            <Tweet id="1747971713311359371" />
            <Tweet id="1775021225267618233" />
            <Tweet id="1775035693921706440" />
            <Tweet id="1784185591166288135" />
          </div>
        </div>
      </article>

      {/*  <div>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 5 }}
          // gutter="10px"
        >
          <Masonry className="flex !gap-4">
            <Tweet id="1775209201352282462" />
            <Tweet id="1777493597916746205" />
            <Tweet id="1775431120957374694" />
            <Tweet id="1767381458170077601" />
            <Tweet id="1778704254372553068" />
            <Tweet id="1755980623448047803" />
            <Tweet id="1767768487760474357" />
            <Tweet id="1757760892694073478" />
            <Tweet id="1757089440890065342" />
            <Tweet id="1751482415371923679" />
            <Tweet id="1743661234359357761" />
            <Tweet id="1775217542606262414" />
            <Tweet id="1747971713311359371" />
            <Tweet id="1775021225267618233" />
            <Tweet id="1775035693921706440" />
          </Masonry>
        </ResponsiveMasonry>
  </div> */}

      {/* <div className="flex gap-8 justify-center items-center">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            stopOnLastSlide: false,
            waitForTransition: true,
          }}
          className="mySwiper text-white z-[9999]"
          modules={[Autoplay]}
        >
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1775209201352282462" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1777493597916746205" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1775431120957374694" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="176738145817007760" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1778704254372553068" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1755980623448047803" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1767768487760474357" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1757760892694073478" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1757089440890065342" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1751482415371923679" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1743661234359357761" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1775217542606262414" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1747971713311359371" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1775021225267618233" />
          </SwiperSlide>
          <SwiperSlide className="flex flex-col p-4">
            <Tweet id="1775035693921706440" />
          </SwiperSlide>
        </Swiper>
        </div> */}
    </div>
  );
}
