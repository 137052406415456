import React from "react";
import { FaXTwitter, FaGithub, FaDiscord } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";

function Footer() {
  return (
    <div className="flex flex-col md:flex-row gap-4 justify-between items-center mb-12 h-auto md:h-[200px]">
      <h2 className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%]">
        NodeOps
      </h2>
      <div>
        <div className="flex gap-4 socialMediaButton">
          <a
            href="https://twitter.com/NodeOps_App"
            target="_blank"
            className="relative z-[9999]"
          >
            <div className="flex justify-center items-center text-white btn">
              <FaXTwitter />
            </div>
          </a>
          <a
            href="https://discord.gg/HftrtmSsyW"
            target="_blank"
            className="relative z-[9999]"
          >
            <div className="flex justify-center items-center text-white btn">
              <FaDiscord />
            </div>
          </a>
          <a
            href="https://github.com/NodeOps-app"
            target="_blank"
            className="relative z-[9999]"
          >
            <div className="flex justify-center items-center text-white btn">
              <FaGithub />
            </div>
          </a>
          <a
            href="mailto:hello@nodeops.xyz"
            target="_blank"
            className="relative z-[9999]"
          >
            <div className="flex justify-center items-center text-white btn">
              <FiMail />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
