import React, { useEffect } from "react";

function Features() {
  useEffect(() => {
    const blocks = document.querySelectorAll(".block");
    const resetDuration = 300;

    blocks.forEach((block) => {
      let timeoutId;
      block.addEventListener("mouseover", () => {
        clearTimeout(timeoutId);
        block.classList.add("active");
        timeoutId = setTimeout(() => {
          block.classList.remove("active");
        }, resetDuration);
      });
    });
  }, []);

  return (
    <div className="flex flex-col justify-center gap-8 md:gap-16 relative z-[9999]">
      <div className="flex flex-col gap-2 md:gap-4 items-center text-white">
        <h1 className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%]">
          Features
        </h1>
        <p className="text-white/60 text-center text-sm md:text-base">
          Eliminating the complexities for web3 onboarding
        </p>
      </div>
      <div className="w-[96%] lg:w-3/4 self-center flex flex-col gap-8">
        <div class="grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 gap-8">
          <div class="grid col-span-1 grid-rows-[25%_calc(75%-2rem)] gap-8">
            <div class="bgCoin border border-white/20 text-white leading-4 flex justify-center items-center gap-2">
              <div className="flex-col text-center">
                <div className="text-base md:text-xl leading-[12px]">
                  Node as a
                </div>
                <div className="text-base md:text-xl text-white/60">
                  {" "}
                  Service
                </div>
              </div>
            </div>
            <div class="bgThunder border border-white/20 text-white leading-4 flex justify-center items-center gap-2">
              <div className="text-base md:text-xl">AI-Powered</div>
              <div className="text-base md:text-xl text-white/60">
                Playground
              </div>
            </div>
          </div>

          {/* <div className="h-[600px] border border-white/20">
            <div className="container">
              <div className="img-container">
                <div className="img">
                  <img src="Thunder.svg" alt="logo" />
                </div>
                <div className="img-blocks">
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                  <div className="block"></div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="grid h-[600px] col-span-1 gap-8">
            <div class="bgLogo border border-white/20 text-white leading-4 flex justify-center items-center gap-2">
              <div className="text-center p-4 mt-[300px]">
                <div className="text-base md:text-xl leading-[12px]">
                  NodeOps AVS
                </div>
                <div className="text-base md:text-xl text-white/60">
                  Powered by EigenLayer
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-rows-[calc(70%-2rem)_30%] gap-8">
            <div class="bgBlocks border border-white/20 text-white">
              <div className="p-4">
                <div className="text-base md:text-xl leading-[12px]">
                  Containerized
                </div>
                <div className="text-base md:text-xl text-white/60">
                  Infrastructure Setup
                </div>
              </div>
            </div>
            <div class="bgTracker border border-white/20 text-white">
              <div className="text-center p-4">
                <div className="text-base md:text-xl leading-[12px]">
                  Monitoring & Vulnerability
                </div>
                <div className="text-base md:text-xl text-white/60">
                  Trackers
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-[calc(75%-6rem)_calc(25%+4rem)] gap-8">
          <div class="border border-white/20 h-[240px] bgTags flex items-end text-white gap-2 p-4">
            <div className="text-base md:text-xl">Preset</div>
            <div className="text-base md:text-xl text-white/60">Templates</div>
          </div>
          <div class="bgDeploy border border-white/20 text-white">
            <div className="p-4">
              <div className="text-base md:text-xl leading-[12px]">
                One Click
              </div>
              <div className="text-base md:text-xl text-white/60">
                Deployment
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
