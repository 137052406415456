import React from "react";
import {
  IoLogoGithub,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoIosGlobe,
} from "react-icons/io";

export default function Team() {
  return (
    <div className="flex flex-col gap-10">
      <div className="font-[Panchang] text-white font-bold text-xl md:text-[2rem] lg:text-[3rem] text-center leading-[120%] z-50">
        Team
      </div>
      <div className="flex flex-wrap px-0 md:px-40 gap-8 text-white justify-center">
        <div className="min-w-[300px] border border-white/20">
          <div className="px-4 py-2">
            <div className="naman"></div>
            Naman Kabra
            <br />
            <p className="text-sm text-white/60">Founder</p>
          </div>
          <div className="flex justify-around border-t border-white/20 socialLink py-3 relative z-10">
            <a href="https://www.linkedin.com/in/namankabra/" target="_blank">
              <IoLogoLinkedin className="text-2xl text-white/60" />
            </a>
            <a href="https://twitter.com/307naman" target="_blank">
              <IoLogoTwitter className="text-2xl text-white/60" />
            </a>
            <a href="https://github.com/nk307" target="_blank">
              <IoLogoGithub className="text-2xl text-white/60" />
            </a>
            <a href="https://twitter.com/307naman" target="_blank">
              <IoIosGlobe className="text-2xl text-white/60" />
            </a>
          </div>
        </div>

        <div className="min-w-[300px] border border-white/20">
          <div className="px-4 py-2">
            <div className="pratik"></div>
            Pratik Balar
            <br />
            <p className="text-sm text-white/60">Tech & Infra</p>
          </div>
          <div className="flex justify-around border-t border-white/20 socialLink py-3 relative z-10">
            <a href="https://www.linkedin.com/in/pratikbalar/" target="_blank">
              <IoLogoLinkedin className="text-2xl text-white/60" />
            </a>
            <a href="https://twitter.com/pratikbin" target="_blank">
              <IoLogoTwitter className="text-2xl text-white/60" />
            </a>
            <a href="https://github.com/pratikbin" target="_blank">
              <IoLogoGithub className="text-2xl text-white/60" />
            </a>
            <a href="https://pratikb.in/" target="_blank">
              <IoIosGlobe className="text-2xl text-white/60" />
            </a>
          </div>
        </div>
        <div className="min-w-[300px] border border-white/20">
          <div className="px-4 py-2">
            <div className="jagdish"></div>
            Jagdish Chudasama
            <br />
            <p className="text-sm text-white/60">Full Stack Developer</p>
          </div>
          <div className="flex justify-around border-t border-white/20 socialLink py-3 relative z-10">
            <a
              href="https://www.linkedin.com/in/chudasama-jagdish-3759981b1/"
              target="_blank"
            >
              <IoLogoLinkedin className="text-2xl text-white/60" />
            </a>
            <a href="https://twitter.com/JagdishChudas15" target="_blank">
              <IoLogoTwitter className="text-2xl text-white/60" />
            </a>
            <a href="https://github.com/chudasamajd" target="_blank">
              <IoLogoGithub className="text-2xl text-white/60" />
            </a>
            <a
              href="https://chudasamajd.github.io/chudasamajd/"
              target="_blank"
            >
              <IoIosGlobe className="text-2xl text-white/60" />
            </a>
          </div>
        </div>
        {/* <div className="flex-1 border border-white/20">Bhauvtik</div> */}
      </div>
    </div>
  );
}
